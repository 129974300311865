import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../../src/layout/index.vue' // 主框架路径
import refresh from '../views/refresh.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Layout,
    },
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('../views/Dashboard.vue'),
                name: 'home',

            },
            {
                path: '/adminList',
                name: 'adminList',
                component: () => import('../views/admin_list.vue'),
            },
            {
                path: '/userlist',
                name: 'user_list',
                component: () => import('../views/user_list.vue'),
            }, {
                path: '/category_type',
                name: 'category_type',
                component: () => import('../views/category_type.vue'),
            }, {
                path: '/storeroom_type',
                name: 'storeroom_type',
                component: () => import('../views/storeroom_type.vue'),
            }, {
                path: '/sku_type',
                name: 'sku_type',
                component: () => import('../views/sku_type.vue'),
            }, {
                path: '/inventoryLog',
                name: 'inventoryLog',
                component: () => import('../views/inventoryLog.vue'),
            }, {
                path: '/products',
                name: 'products',
                component: () => import('../views/products.vue'),
            },
            {
                path: '/products_add',
                name: 'products_add',
                component: () => import('../views/products_add.vue'),
            },{
                path: '/products_edit',
                name: 'products_edit',
                component: () => import('../views/products_edit.vue'),
            },{
                path: '/orderslist',
                name: 'orderslist',
                component: () => import('../views/orderslist.vue'),
            },
            {
                path: '/windows',
                name: 'windows',
                component: () => import('../views/windows.vue'),
            }, {
                path: '/article_list',
                name: 'article_list',
                component: () => import('../views/article_list.vue'),
            },{
                path: '/appuser_list',
                name: 'appuser_list',
                component: () => import('../views/appuser_list.vue'),
            },{
                path: '/coupons_list',
                name: 'coupons_list',
                component: () => import('../views/coupons_list.vue'),
            },{
                path: '/ad_space',
                name: 'ad_space',
                component: () => import('../views/ad_space.vue'),
            },{
                path: '/message_list',
                name: 'message_list',
                component: () => import('../views/message_list.vue'),
            },{
                path: '/team_list',
                name: 'team_list',
                component: () => import('../views/team_list.vue'),
            },{
                path: '/comment_list',
                name: 'comment_list',
                component: () => import('../views/comment_list.vue'),
            },{
                path: '/orderslist_point',
                name: 'orderslist_point',
                component: () => import('../views/orderslist_point.vue'),
            },
            {
                path: '/checkEmploye_list',
                name: 'checkEmploye_list',
                component: () => import('../views/checkEmploye_list.vue'),
            },            {
                path: '/countOrder',
                name: 'countOrder',
                component: () => import('../views/countOrder.vue'),
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: refresh
    },
]

const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(), //带#
    routes
})

export default router
